
import image from '@images/examples/kaarsje-resormeren.png'
import {relativeRoute} from "@/helpers/relativeRoute";
import {useApp} from "@/Providers/AppProvider";

export default function Onboarding() {
  const {onboarding} = useApp()
  return <section className="card">
    <div>
      <img src={image} alt=""/>
      <div>
        <h2>Overlijden bekendmaken</h2>

        {onboarding ? <>
          <p>Maak een overlijden bekend via een online rouwpagina of via een rouwadvertentie in de krant.</p>
          <a className="button" href={relativeRoute('create')}>Online rouwbericht opstellen</a>
          {/*<a className="button" href="https://www.familieberichten.nl/home" target="_blank">Rouwadvertentie opstellen</a>*/}
        </> : <>
          <p>Stel eenvoudig een rouwadvertentie op die je kunt laten plaatsen in vrijwel alle kranten:
            landelijke dagbladen, regionale nieuwsbladen en huis-aan-huis bladen.</p>
          <a className="button" href={"https://www.familieberichten.nl/home"} target={'_blank'}>Rouwadvertentie
            opstellen</a>


        </>}



      </div>

    </div>

  </section>

}
